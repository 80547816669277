import React, { Component } from "react";

import HorisontalCard from "../../../components/horisontal-card/HorisontalCard";

import danger from "../../../assets/danger.svg";

import "./MVPAttantionSectionStyles.scss";

class MVPAttantionSection extends Component {
    render() {
        return (
            <section className="section mvp-attention-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <HorisontalCard
                                icon={danger}
                                text="Работы по созданию MVP-версии HRFile, которую мы готовы представить российскому рынку, еще не завершены. Продукт находится в разработке, мы близимся к финальной стадии."
                                altName="Attantion icon"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default MVPAttantionSection;
