import React, { Component } from "react";

import ancorProduct from "../../../images/ancorProduct.png";

import "./ancorProductsSectionStyles.scss";

class AncorProductsSection extends Component {
    render() {
        return (
            <section className="section ancor-product">
                <div className="container">
                    <h2 className="ancor-product_title">
                        HRFile – продукт группы <br /> компаний ANCOR
                    </h2>
                    <div className="row ancor-product_content">
                        <div className="col-12 col-lg-3 ancor-product_statictics">
                            <div className="row">
                                <div className="statistic-item col-12 col-sm-6 col-lg-12">
                                    <div className="statistic-item_title">
                                        1300+
                                    </div>
                                    <div className="statistic-item_description">
                                        проектов по аутсорсингу
                                    </div>
                                </div>
                                <div className="statistic-item col-12 col-sm-6 col-lg-12">
                                    <div className="statistic-item_title">
                                        20 000+
                                    </div>
                                    <div className="statistic-item_description">
                                        сотрудников. Найм и кадровое
                                        обслуживание
                                    </div>
                                </div>
                                <div className="statistic-item col-12 col-sm-6 col-lg-12">
                                    <div className="statistic-item_title">
                                        Unistaff
                                    </div>
                                    <div className="statistic-item_description">
                                        Расчет заработной платы и кадровое
                                        делопроизводство
                                    </div>
                                </div>
                                <div className="statistic-item col-12 col-sm-6 col-lg-12">
                                    <div className="statistic-item_title">
                                        ФЗ-152
                                    </div>
                                    <div className="statistic-item_description">
                                        Безупречная работа с большим объемом
                                        персональных данных.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 align-self-end ancor-product_image">
                            <img
                                src={ancorProduct}
                                alt="Продукт группы компаний ANCOR, основная картинка"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AncorProductsSection;
