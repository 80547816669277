import React, { Component } from "react";

import Header from "../sections/commonSections/header/HeaderSection";
import Main from "../sections/commonSections/main/MainSection";
import Footer from "../sections/commonSections/footer/FooterSection";

import WhatGives from "../sections/mainPage/whatGivesSection/WhatGivesSection";
import HowWork from "../sections/mainPage/howWorkSection/HowWorkSection";
import Advantage from "../sections/mainPage/advantageSection/AdvantageSection";
import AncorProduct from "../sections/mainPage/ancorProductsSection/AncorProductsSection";
import AncorProjects from "../sections/mainPage/ancorProjectsSection/AncorProjectsSection";
import Safety from "../sections/mainPage/safetySection/SafetySection";
import MVPAttantion from "../sections/mainPage/MVPAttantionSection/MVPAttantionSection";
import PriceSection from "../sections/mainPage/priceSection/PriceSection";

import "../styles/index.scss";

class index extends Component {
    render() {
        return (
            <div>
                <Header />
                <Main />
                <WhatGives />
                <HowWork />
                <Advantage />
                <AncorProduct />
                <AncorProjects />
                <Safety />
                <MVPAttantion />
                <PriceSection />
                <Footer />
            </div>
        );
    }
}

export default index;

export const Head = () => <title> HRFile </title>;
