import React, { Component, Fragment } from "react";

import Title from "../../../components/Title";
import GivesCard from "../../../components/GivesCard";

import employee from "../../../images/employee.svg";
import hr from "../../../images/HR.svg";
import organization from "../../../images/organization.svg";

import "./whatGivesSectionStyles.scss";

class WhatGivesSection extends Component {
    render() {
        return (
            <section className="section what-give-section">
                <div className="container">
                    <div className="row">
                        <div className="col section-title">
                            <Title title="Что дает HRFile?" />
                        </div>
                    </div>

                    <div className="row gx-5">
                        <div className="col-md-4 col-sm-12 mb-3 mb-xs-3 mb-sm-3 mb-md-0">
                            <div className="gives-card">
                                <div className="gives-card_image">
                                    <img
                                        src={organization}
                                        alt="Для организаций"
                                    />
                                </div>
                                <div className="gives-card_body">
                                    <div className="gives-card_title mb-sm-3">
                                        Для организаций
                                    </div>
                                    <div className="gives-card_description">
                                        перенос кадрового документооборота в
                                        электронный вид.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 mb-3 mb-xs-3 mb-sm-3 mb-md-0">
                            <div className="gives-card">
                                <div className="gives-card_image">
                                    <img src={employee} alt="Для сотрудника" />
                                </div>
                                <div className="gives-card_body">
                                    <div className="gives-card_title mb-sm-3">
                                        Для сотрудника
                                    </div>
                                    <div className="gives-card_description">
                                        личный кабинет на HR-портале
                                        длябезбумажного обмена документами с
                                        компанией.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12  mb-3 mb-xs-3 mb-sm-3 mb-md-0">
                            <div className="gives-card">
                                <div className="gives-card_image">
                                    <img src={hr} alt="Для отдела кадров" />
                                </div>
                                <div className="gives-card_body">
                                    <div className="gives-card_title mb-sm-3">
                                        Для отдела кадров
                                    </div>
                                    <div className="gives-card_description">
                                        удобный и доступный инструмент
                                        автоматизации кадрового делопроизводства
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhatGivesSection;
