import React, { Component } from "react";

import HorisontalCard from "../../../components/horisontal-card/HorisontalCard";

import Price from "../../../assets/price.svg";

import "./priceSectionStyles.scss";

class PriceSection extends Component {
    renderPrice = () => {
        return (
            <span>
                За более подробной информацией обращайтесь, пожалуйста, в отдел
                продаж по телефону:{" "}
                <a href="tel:8 800 700-52-03" className="text-link">
                    8 800 700-52-03
                </a>{" "}
                или по электронной почте{" "}
                <a href="mailto:info@hrfile.ru" className="text-link">
                    info@hrfile.ru
                </a>
            </span>
        );
    };
    render() {
        return (
            <section className="section mvp-attention-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <HorisontalCard
                                icon={Price}
                                text={this.renderPrice()}
                                altName="Attantion icon"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PriceSection;
