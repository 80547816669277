import React, { Component } from "react";

import HorisontalCard from "../../../components/horisontal-card/HorisontalCard";

import twoFactorIcon from "../../../images/twoFactorIcon.svg";
import safetyIcon from "../../../images/safetyIcon.svg";
import databaseIcon from "../../../images/databaseIcon.svg";
import testingIcon from "../../../images/testingIcon.svg";

import "./safetySectionStyles.scss";

class SafetySection extends Component {
    render() {
        return (
            <section className="section safety-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-8 safety-section-content">
                            <div className="safety-block-content_title">
                                Безопасность
                            </div>
                            <div className="safety-block-content_description">
                                {
                                    "Применение современных инструментов обеспечения кибербезопасности в разработке и поддержке HRFile и других ИТ-продуктов:"
                                }
                            </div>

                            <div className="safety-block-content_cards">
                                <HorisontalCard
                                    altName="Двухфакторная аутентификация"
                                    icon={twoFactorIcon}
                                    text="Двухфакторная аутентификация при входе в личный кабинет"
                                />
                                <HorisontalCard
                                    altName="Защита сервера"
                                    icon={safetyIcon}
                                    text="Защита сервера от ddos-атак и несанкционированного доступа"
                                />
                                <HorisontalCard
                                    altName="База данных"
                                    icon={databaseIcon}
                                    text="Надежная база данных с ежедневным резервным копированием"
                                />
                                <HorisontalCard
                                    altName="Тестирование"
                                    icon={testingIcon}
                                    text="Регулярное тестирование на угрозы"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-xl-3 offset-xl-1">
                            <div className="safety-section-mvp-content p-5">
                                <div className="row ">
                                    <div className="col mvp-content-title text-lg-left text-center">
                                        Результаты MVP:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 col-4 col-xl-12 mvp-content-item">
                                        <div className="mvp-content-item_title">
                                            {"> 60 000"}
                                        </div>
                                        <div className="mvp-content-item_description">
                                            пользователей
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-4 col-xl-12 mvp-content-item">
                                        <div className="mvp-content-item_title">
                                            4,7 из 5
                                        </div>
                                        <div className="mvp-content-item_description">
                                            средняя оценка сервиса
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-4 col-xl-12 mvp-content-item">
                                        <div className="mvp-content-item_title">
                                            на 60%
                                        </div>
                                        <div className="mvp-content-item_description">
                                            сокращение человеческих ресурсов
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SafetySection;
