import React, { Component } from 'react';

class GivesCard extends Component {
    render() {
        const { img, title, desctiption, altName } = this.props;

        return (
            <div className='gives-card'>
                { img && (
                    <div className='gives-card_image'>
                        <img src={ img } alt={altName} />
                    </div>
                )}
                { title && (
                    <div className='gives-card_title'>
                        { title }
                    </div>
                )}
                { desctiption && (
                    <div className='gives-card_description'>
                        { desctiption }
                    </div>
                )}
            </div>
        );
    }
}

export default GivesCard;
