import React, { Component } from "react";

import "./horisontalCardStyles.scss";

class HorisontalCard extends Component {
    getIcon = () => {
        const { icon, altName } = this.props;

        if (typeof icon === "function") {
            const IconComponent = icon;
            return <IconComponent />;
        } else {
            return icon && <img src={icon} alt={altName} />;
        }
    };

    getText = () => {
        const { text } = this.props;
        console.log("text", text);

        if (typeof text === "function") {
            const TextComponent = text;
            return <TextComponent />;
        } else {
            return text;
        }
    };

    render() {
        return (
            <div className="horisontal-card">
                <div className="horisontal-card_icon">{this.getIcon()}</div>
                <div className="horisontal-card_text">{this.getText()}</div>
            </div>
        );
    }
}

export default HorisontalCard;
