import React, { Component } from 'react';

class Button extends Component {
    render() {
        const { type = 'default', text, onClick, htmlType } = this.props;

        return (
            <button type={htmlType} className={`button ${ type }`} onClick={() => onClick && onClick()}>
                { text }
            </button>
        );
    }
}

export default Button;
