import React, { Component } from "react";

import Title from "../../../components/Title";
import Button from "../../../components/Button";

import GPH from "../../../images/GPH.svg";
import TD from "../../../images/TD.svg";

import "./howWorkSectionStyles.scss";

import ArrowDown from "../../../assets/howWork/arrowDown.svg";
import ChangeDocument from "../../../assets/howWork/changeDocument.svg";
import CheckDocument from "../../../assets/howWork/checkDocument.svg";
import CreateDocument from "../../../assets/howWork/createDocument.svg";
import DownloadDocuments from "../../../assets/howWork/downloadDocuments.svg";
import RemoveDocument from "../../../assets/howWork/removeDocument.svg";
import SignDocument from "../../../assets/howWork/signDocument.svg";

const TD_CONTRACT_TYPE = "TD";
const GPH_CONTRACT_TYPE = "GPH";

class HowWorkSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contractType: TD_CONTRACT_TYPE
        };
    }

    onChangeContractType = (selectedContractType) => {
        const { contractType } = this.state;

        if (selectedContractType !== contractType) {
            this.setState({ contractType: selectedContractType });
        }
    };

    render() {
        const { contractType } = this.state;

        return (
            <section className="section how-work-section">
                <div className="container">
                    <div className="row">
                        <div className="col section-title">
                            <Title title="Как работает кадровый портал HRFile?" />
                        </div>
                    </div>

                    <div className="row justify-content-center mb-5">
                        <p className="col-12 col-sm-6 col-md-4">
                            КЭДО или кадровый электронный документооборот - это
                            создание, подписание и хранение документов,
                            связанных с выполнением работы, в электронном виде
                            без бумажных носителей.
                        </p>
                        <p className="col-12 col-sm-6 col-md-4">
                            HRFile обеспечивает надежный и безопасный обмен
                            кадровыми документами в электронном формате между
                            работодателем и работником, или лицом, принимающимся
                            на работу.
                        </p>
                    </div>
                    <div className="row justify-content-center mb-5 g-2">
                        <div className="col-12 col-md-6 col-lg-4 pl-0">
                            <Button
                                text="Наем на трудовой договор"
                                type={
                                    contractType === TD_CONTRACT_TYPE &&
                                    "primary"
                                }
                                onClick={() =>
                                    this.onChangeContractType(TD_CONTRACT_TYPE)
                                }
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 pr-0">
                            <Button
                                text="Наем на договор ГПХ"
                                type={
                                    contractType === GPH_CONTRACT_TYPE &&
                                    "primary"
                                }
                                onClick={() =>
                                    this.onChangeContractType(GPH_CONTRACT_TYPE)
                                }
                            />
                        </div>
                    </div>
                    {contractType === GPH_CONTRACT_TYPE && (
                        <div className="how-works-items row justify-content-center">
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-1 ">
                                    <div className="how-works-item_icon">
                                        <DownloadDocuments />
                                    </div>
                                    <div className="how-works-item_text">
                                        Сбор и загрузка документов для приема на
                                        работу
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-2">
                                    <div className="how-works-item_icon">
                                        <CheckDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Верификация документов
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-3">
                                    <div className="how-works-item_icon">
                                        <CreateDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Формирование договора ГПХ
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-4">
                                    <div className="how-works-item_icon">
                                        <SignDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Электронное подписание договора
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-5">
                                    <div className="how-works-item_icon">
                                        <CreateDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Формирование задания, акта
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-6">
                                    <div className="how-works-item_icon">
                                        <SignDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Электронное подписание задания, акта
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-7">
                                    <div className="how-works-item_icon">
                                        <ChangeDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Запрос на изменение персональных данных
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-8">
                                    <div className="how-works-item_icon">
                                        <RemoveDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Расторжение договора
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {contractType === TD_CONTRACT_TYPE && (
                        <div className="how-works-items row justify-content-center">
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-1 ">
                                    <div className="how-works-item_icon">
                                        <DownloadDocuments />
                                    </div>
                                    <div className="how-works-item_text">
                                        Сбор и загрузка документов для приема на
                                        работу
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-2">
                                    <div className="how-works-item_icon">
                                        <CheckDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Верификация документов
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-3">
                                    <div className="how-works-item_icon">
                                        <CreateDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Формирование договора
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-4">
                                    <div className="how-works-item_icon">
                                        <SignDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Электронное подписание договора
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-5">
                                    <div className="how-works-item_icon">
                                        <CreateDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Заявление на отпуск, отсутствие
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-6">
                                    <div className="how-works-item_icon">
                                        <CreateDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Оформление командировки
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-7">
                                    <div className="how-works-item_icon">
                                        <ChangeDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Запрос на изменение персональных данных
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                            <div className="how-works-item-wrapper col-12 col-md-12 col-lg-8">
                                <div className="how-works-item how-works-item-color-8">
                                    <div className="how-works-item_icon">
                                        <RemoveDocument />
                                    </div>
                                    <div className="how-works-item_text">
                                        Расторжение договора
                                    </div>
                                    <div className="how-works-item_arrow">
                                        <ArrowDown />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* </div> */}
            </section>
        );
    }
}

export default HowWorkSection;
