import React, { Component } from "react";

import "./cadrStyle.scss";

class Card extends Component {
    renderDescription = () => {
        const { description } = this.props;

        if (Array.isArray(description)) {
            return (
                <ul className="description-list">
                    {description.map((descriptionItem) => {
                        return (
                            <li className="description-list_item">
                                {descriptionItem}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return description;
        }
    };

    render() {
        const { title, icon, altName } = this.props;

        return (
            <div className="card">
                <div className="card_icon">
                    {icon && <img src={icon} alt={altName} />}
                </div>
                <div className="card-body">
                    <div className="card_title">{title}</div>
                    <div className="card_description">
                        {this.renderDescription()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;
