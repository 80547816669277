import React, { Component, Fragment } from "react";

import Title from "../../../components/Title";
import Card from "../../../components/card/Card";

import pig from "../../../images/pig.svg";
import rocket from "../../../images/rocket.svg";
import stand from "../../../images/stand.svg";

import "./advantageSectionStyles.scss";

class AdvantageSection extends Component {
    render() {
        return (
            <section className="section advantage-section">
                <div className="container">
                    <div className="row">
                        <div className="col section-title">
                            <Title title="Преимущество HRFile" />
                        </div>
                    </div>

                    <div className="row g-4">
                        <div className="col-12 col-lg-4">
                            <Card
                                icon={pig}
                                title="Экономия"
                                description={[
                                    "временных затрат инспектора отдела кадров и сотрудника;",
                                    "финансовых затрат компании на печать, хранение документов и курьерские услуги;",
                                    "временных затрат на подготовку к проверкам;",
                                    "офисного пространства за счет отсутствия бумажного архива."
                                ]}
                                altName="Экономия"
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <Card
                                icon={rocket}
                                title="Скорость"
                                description={[
                                    "найма сотрудника;",
                                    "доставки документов;",
                                    "уведомления сотрудников за счет интеграции HRFile с сервисами быстрых сообщений;",
                                    "предоставления отчетности за счет интеграции HRFile c ФИАС, ФНС, учетными системами компании."
                                ]}
                                altName="Скорость"
                            />
                        </div>
                        <div className="col-12 col-lg-4">
                            <Card
                                icon={stand}
                                title="Привлекательность"
                                description={[
                                    "работодателя для кандидатов и сотрудников;",
                                    "прозрачности процессов и актуальности данных для аудиторов;",
                                    "отчетности и аналитики для HR-службы и руководства компании."
                                ]}
                                altName="Привлекательность"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AdvantageSection;
