import React, { Component } from "react";

import experium from "../../../images/experium.svg";
import planka from "../../../images/planka.svg";
import staffjet from "../../../images/staffjet.svg";
import SFM from "../../../images/SFM.svg";
import meConsultant from "../../../images/meConsultant.svg";
import podbor from "../../../images/podbor.svg";

import "./ancorProjectsSectionStyles.scss";

class AncorProjectsSection extends Component {
    render() {
        return (
            <section className="section ancor-projects-section">
                <div className="container">
                    <div className="ancor-projects-block_title row align-items-center">
                        <div className="col">Другие цифровые проекты ANCOR</div>
                    </div>
                    <div className="row g-4">
                        <div className="ancor-project col-12 col-sm-6 col-md-4 col-xl-2 col-lg-4 ">
                            <a
                                href="https://experium.ru/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="ancor-project-logo"
                                    src={experium}
                                    alt="Логотип experium"
                                />
                            </a>
                        </div>
                        <div className="ancor-project col-12 col-sm-6 col-md-4 col-xl-2 col-lg-4">
                            <a
                                href="https://ancor.ru/ecosystem/proftests/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="ancor-project-logo"
                                    src={planka}
                                    alt="Логотип planka"
                                />
                            </a>
                        </div>
                        <div className="ancor-project col-12 col-sm-6 col-md-4 col-xl-2 col-lg-4">
                            <a
                                href="https://staffjet.ru/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="ancor-project-logo"
                                    src={staffjet}
                                    alt="Логотип staffjet"
                                />
                            </a>
                        </div>
                        <div className="ancor-project col-12 col-sm-6 col-md-4 col-xl-2 col-lg-4">
                            <a
                                href="https://ancor.ru/ecosystem/sfm/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="ancor-project-logo"
                                    src={SFM}
                                    alt="Логотип SFM"
                                />
                            </a>
                        </div>
                        <div className="ancor-project col-12 col-sm-6 col-md-4 col-xl-2 col-lg-4">
                            <a
                                href="https://ancor.ru/ecosystem/meconsultant/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="ancor-project-logo"
                                    src={meConsultant}
                                    alt="Логотип meConsultant"
                                />
                            </a>
                        </div>
                        <div className="ancor-project col-12 col-sm-6 col-md-4 col-xl-2 col-lg-4">
                            <a
                                href="https://podbor.io/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="ancor-project-logo"
                                    src={podbor}
                                    alt="Логотип podbor"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AncorProjectsSection;
